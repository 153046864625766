import { httpClient } from 'shared/services/http-client';
import { getUrl } from '../get-url';

export async function moveFile(oldFileKey: string, hardwareId: string, firmwareVersion: string) {
  const body = JSON.stringify({ filekey: `hardware/${hardwareId}/firmware/${firmwareVersion}.zip` });

  try {
    const res = await httpClient(oldFileKey, {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
        'Access-Control-Allow-Origin': '*',
      }),
      body,
    });

    return { fileKey: getUrl(JSON.parse(res.body).link ?? oldFileKey) };
  } catch {
    return { fileKey: oldFileKey };
  }
}
