import { Create, SimpleForm, TextInput, useNotify } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';

export const DeviceCreate = () => {
  const notify = useNotify();

  const handleSubmit = async (data: any) => {
    try {
      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.createDevices}`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
        }),
      });

      notify('Устройство добавлено', { type: 'success' });
    } catch (error: any) {
      if (error.message) {
        notify(
          `Что-то пошло не так: ${error?.message}
        `,
          { type: 'error' },
        );
      } else {
        notify(`Что-то пошло не так...`, { type: 'error' });
      }
    }
  };

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="name" />
        <TextInput source="alias" />
        <TextInput source="mac" />
        <TextInput source="hardwareId" />
        <TextInput source="firmwareId" />
        <TextInput source="userId" />
      </SimpleForm>
    </Create>
  );
};
