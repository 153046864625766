import { API_BASE_URL_FS } from 'shared/config';
import { httpClient } from 'shared/services/http-client';
import { getUrl } from '../get-url';

export async function uploadFile(file: File, hardwareId: string, firmwareVersion: string) {
  const multipart = new FormData();
  multipart.append('file', file);
  multipart.append('filekey', `hardware/${hardwareId}/firmware/${firmwareVersion}.zip`);

  const res = await httpClient(`${API_BASE_URL_FS}`, {
    method: 'POST',
    headers: new Headers({
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
      'Access-Control-Allow-Origin': '*',
    }),
    body: multipart,
  });

  return { fileKey: getUrl(JSON.parse(res.body).link) };
}
