import { parse, stringify } from 'query-string';
import { isValidSortingValue } from './is-valid-sorting-value';

export const replaceQueryParams = (searshQuery: string) => {
  const parsedQuery = parse(searshQuery);

  const queryKeys = Object.keys(parsedQuery);

  const sortKey = queryKeys.find(item => item.includes('sort'));

  for (const filterKey of queryKeys.filter(item => item.startsWith('filter'))) {
    const filter = parsedQuery[filterKey];

    if (typeof filter === 'string') {
      const filterArr = filter.split('||cont||');
      parsedQuery[filterArr[0]] = filterArr[1];
      delete parsedQuery[filterKey];
    }
  }

  if (sortKey) {
    const sortValues = (parsedQuery[sortKey] as string).split(',');

    if (isValidSortingValue(sortValues[0])) {
      parsedQuery.order = sortValues[1];
      parsedQuery.orderTerms = sortValues[0];
    }

    delete parsedQuery[sortKey];
    delete parsedQuery['offset'];
  }

  return stringify(parsedQuery);
};
