import { LoginByEmail } from 'shared/components';
import styled from 'styled-components';

export const SignInPage = () => {
  return (
    <Root>
      <LoginByEmail />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fafafb;
`;
