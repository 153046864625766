import {
  BooleanInput,
  Edit,
  Loading,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { AdminModelResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { isRequired } from 'shared/validators';
import { CartridgesCustomEditToolbar } from './ui/custom-toolbar';
import { useParams } from 'react-router-dom';
import { httpClient } from 'shared/services/http-client';
import { API_BASE_URL_ADMIN } from 'shared/config';

export const CartridgeEdit = () => {
  const { id } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();

  const { data, isLoading } = useHttpClient<AdminModelResponseDto>(API_URL.getModels);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const modelOptions = data.data.map((model: any) => ({
    id: model.id,
    name: model.name,
  }));

  const isDeleted = Boolean(data?.deletedAt);

  if (isLoading) {
    return <Loading />;
  }

  if (!data || !id) return null;

  const handleSubmit = async (data: any) => {
    try {
      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.updateCartridge(id)}`, {
        method: 'PATCH',
        body: JSON.stringify({ ...data, modelId: data.model.id }),
      });

      notify('Прошивка добавлена', { type: 'success' });
      redirect('/cartridges');
    } catch (error) {
      notify('Что-то пошло не так...', { type: 'error' });
    }
  };

  return (
    <Edit>
      <SimpleForm defaultValues={{ isDeleted }} toolbar={<CartridgesCustomEditToolbar />} onSubmit={handleSubmit}>
        <SelectInput
          source="model.id"
          validate={[isRequired]}
          label={LABELS.model}
          choices={modelOptions}
          optionText="name"
        />
        <TextInput source="formula" label={LABELS.formula} validate={[isRequired]} />
        <TextInput source="flavor" label={LABELS.flavor} validate={[isRequired]} />
        <TextInput source="comment" label={LABELS.comment} validate={[isRequired]} />
        <NumberInput source="rMin" label={LABELS.rMin} validate={[isRequired]} />
        <NumberInput source="rMax" label={LABELS.rMax} validate={[isRequired]} />
        <NumberInput source="factoryId" label={LABELS.factoryId} validate={[isRequired]} />
        <BooleanInput source="isDeleted" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
      </SimpleForm>
    </Edit>
  );
};
