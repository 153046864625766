import { Card, CardContent, CircularProgress } from '@mui/material';
import {
  useLogin,
  useNotify,
  Form,
  TextInput,
  required,
  useSafeSetState,
  Button,
  LoginFormClasses,
  PasswordInput,
} from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const LoginByEmail = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const notify = useNotify();
  const login = useLogin();

  const onSubmit = (values: any) => {
    setLoading(true);
    login(values)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning',
            messageArgs: {
              _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
            },
          },
        );
      });
  };

  return (
    <Form mode="onChange" onSubmit={onSubmit}>
      <Card>
        <CardContent>
          <TextInput autoFocus source="email" label={LABELS.email} validate={required()} fullWidth />
          <PasswordInput
            source="password"
            label={LABELS.password}
            validate={required()}
            autoComplete="current-password"
            fullWidth
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading}
            className={LoginFormClasses.button}
          >
            {loading ? (
              <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
            ) : (
              <span>Войти</span>
            )}
          </Button>
        </CardContent>
      </Card>
    </Form>
  );
};
