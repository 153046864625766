import dayjs from 'dayjs';
import { FORMATS } from 'shared/constants/formats';

export const getValidDatesRange = () => {
  const nowDate = dayjs();
  const before18YearsDate = nowDate.subtract(18, 'year');
  const before18YearsDateFormatted = before18YearsDate.format(FORMATS.date);

  return { min: '1900-01-01', max: before18YearsDateFormatted };
};
