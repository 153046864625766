export enum ResourseNames {
  users = 'users',
  devices = 'devices',
  models = 'models',
  cartridges = 'cartridges',
  hardwares = 'hardwares',
  firmwares = 'firmwares',
  admins = 'admins',
  puffs = 'puffs',
  featureToggles = 'feature-toggle',
}
