import dayjs from 'dayjs';
import { FORMATS } from 'shared/constants/formats';

export const dateFormatter = (date: Date) => {
  if (!(date instanceof Date)) return;

  return dayjs.utc(date).format(FORMATS.date);
};

export const dateParser = (dateString?: string) => {
  const dayJsDate = dayjs.utc(dateString, FORMATS.date);
  const isDateValid = dayJsDate.isValid();

  if (isDateValid) return dayJsDate.toDate();
};
