/* eslint-disable react/jsx-key */
import React from 'react';
import { Resource } from 'react-admin';
import { ResourseNames } from 'shared/types';
import { DeviceList } from 'shared/widgets/devices/list';
import { DeviceShow } from 'shared/widgets/devices/show';
import { FirmwareList } from 'shared/widgets/firmwares/list';
import { FirmwareShow } from 'shared/widgets/firmwares/show';
import { HardwareList } from 'shared/widgets/hardwares/list';
import { HardwareShow } from 'shared/widgets/hardwares/show';
import { UserList } from 'shared/widgets/users/list';
import { UserShow } from 'shared/widgets/users/show';
import { CartrigdesList } from 'shared/widgets/cartridges/list';
import { CartridgeShow } from 'shared/widgets/cartridges/show';
import { ModelsShow } from 'shared/widgets/models/show';
import { ModelsList } from 'shared/widgets/models/list';
import { UserCreate } from 'shared/widgets/users/create';
import { UserEdit } from 'shared/widgets/users/edit';
import { ModelCreate } from 'shared/widgets/models/create';
import { ModelEdit } from 'shared/widgets/models/edit';
import { CartridgeEdit } from 'shared/widgets/cartridges/edit';
import { FirmwareCreate } from 'shared/widgets/firmwares/create';
import { CartridgeCreate } from 'shared/widgets/cartridges/create';
import { HardwareEdit } from 'shared/widgets/hardwares/edit';
import { HardwareCreate } from 'shared/widgets/hardwares/create';
import { DeviceEdit } from 'shared/widgets/devices/edit';
import { DeviceCreate } from 'shared/widgets/devices/create';
import { FirmwareEdit } from 'shared/widgets/firmwares/edit';
import { Permissions, Roles } from '../types';
import { hasPermission } from '../shared/validators';
import {FeatureToggleList} from "../shared/widgets/feature-toggle/list";
import {FeatureToggleEdit} from "../shared/widgets/feature-toggle/edit";

export const resources = (role: Roles) => {
  const resourceWithPermission = (
    name: ResourseNames,
    components: {
      list?: React.ComponentType;
      show?: React.ComponentType;
      create?: React.ComponentType;
      edit?: React.ComponentType;
    },
    permissions: { list?: Permissions; show?: Permissions; create?: Permissions; edit?: Permissions },
  ) => {
    return (
      <Resource
        name={name}
        list={permissions.list && hasPermission(role, permissions.list) ? components.list : undefined}
        show={permissions.show && hasPermission(role, permissions.show) ? components.show : undefined}
        create={permissions.create && hasPermission(role, permissions.create) ? components.create : undefined}
        edit={permissions.edit && hasPermission(role, permissions.edit) ? components.edit : undefined}
      />
    );
  };

  return [
    resourceWithPermission(
      ResourseNames.users,
      {
        list: UserList,
        show: UserShow,
        create: UserCreate,
        edit: UserEdit,
      },
      {
        list: Permissions.ReadUsers,
        show: Permissions.ReadUsers,
        create: Permissions.CreateUsers,
        edit: Permissions.EditUsers,
      },
    ),

    resourceWithPermission(
      ResourseNames.devices,
      {
        list: DeviceList,
        show: DeviceShow,
        create: DeviceCreate,
        edit: DeviceEdit,
      },
      {
        list: Permissions.ReadDevices,
        show: Permissions.ReadDevices,
        create: Permissions.CreateDevices,
        edit: Permissions.EditDevices,
      },
    ),

    resourceWithPermission(
      ResourseNames.firmwares,
      {
        list: FirmwareList,
        show: FirmwareShow,
        create: FirmwareCreate,
        edit: FirmwareEdit,
      },
      {
        list: Permissions.ReadFirmwares,
        show: Permissions.ReadFirmwares,
        create: Permissions.CreateFirmwares,
        edit: Permissions.EditFirmwares,
      },
    ),

    resourceWithPermission(
      ResourseNames.hardwares,
      {
        list: HardwareList,
        show: HardwareShow,
        create: HardwareCreate,
        edit: HardwareEdit,
      },
      {
        list: Permissions.ReadHardwares,
        show: Permissions.ReadHardwares,
        create: Permissions.CreateHardwares,
        edit: Permissions.EditHardwares,
      },
    ),

    resourceWithPermission(
      ResourseNames.cartridges,
      {
        list: CartrigdesList,
        show: CartridgeShow,
        create: CartridgeCreate,
        edit: CartridgeEdit,
      },
      {
        list: Permissions.ReadCartridges,
        show: Permissions.ReadCartridges,
        create: Permissions.CreateCartridges,
        edit: Permissions.EditCartridges,
      },
    ),

    resourceWithPermission(
      ResourseNames.models,
      {
        list: ModelsList,
        show: ModelsShow,
        create: ModelCreate,
        edit: ModelEdit,
      },
      {
        list: Permissions.ReadModels,
        show: Permissions.ReadModels,
        create: Permissions.CreateModels,
        edit: Permissions.EditModels,
      },
    ),

    resourceWithPermission(
      ResourseNames.featureToggles,
      {
        list: FeatureToggleList,
        edit: FeatureToggleEdit,
      },
      {
        list: Permissions.ReadFeatureToggles,
        edit: Permissions.EditFeatureToggles,
      },
    ),
  ];
};