import { FC } from 'react';
import { Tab, TabbedShowLayout, TextField, DateField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { AdminHardwareResponseDto } from 'shared/api';
import { APP_BASE_URL } from 'shared/config';

interface Props {
  data?: AdminHardwareResponseDto;
}

export const HardwareShowContent: FC<Props> = ({ data }) => {
  return (
    <TabbedShowLayout>
      <Tab label="Основная информация">
        <TextField source="id" label={LABELS.id} />
        <TextField source="version" label={LABELS.version} />
        <div>
          <div style={{ fontFamily: 'sans-serif', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', marginBottom: '3px' }}>
            Модель
          </div>
          <a href={`${APP_BASE_URL}/models/${data?.model.id}/show`}>
            <TextField source="model.name" label={LABELS.model} />
          </a>
        </div>
        <DateField source="createdAt" label={LABELS.addedAt} />

        {data?.deletedAt && <DateField source="deletedAt" label={LABELS.deletedAt} />}
      </Tab>
    </TabbedShowLayout>
  );
};
