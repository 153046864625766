import { httpClient } from 'shared/services/http-client';

export async function deleteFile(fileKey: string) {
  return httpClient(fileKey, {
    method: 'DELETE',
    headers: new Headers({
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
      'Access-Control-Allow-Origin': '*',
    }),
  }).catch(() => {
    return;
  });
}
