import { httpClient } from 'shared/services/http-client';
import { API_URL } from 'shared/constants/api-url';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { getUrl } from './get-url';

export async function uploadFile(file: File, hardwareId: string, firmwareVersion: string) {
  const { type } = file;

  const body = JSON.stringify({
    hardwareId,
    firmwareVersion,
  });

  const signRes = await httpClient(`${API_BASE_URL_ADMIN}${API_URL.uploadFirmwareFile}`, { method: 'POST', body });

  await httpClient(JSON.parse(signRes.body).fileKey, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': type,
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
      'Access-Control-Allow-Origin': '*',
    }),
    body: file,
  });

  return { fileKey: getUrl(JSON.parse(signRes.body).fileKey) };
}
