import { FC } from 'react';
import { Tab, TabbedShowLayout, TextField, WrapperField, DateField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { Link } from '@mui/material';
import { APP_BASE_URL } from 'shared/config';
import { AdminUserWithDevicesResponseDto } from 'shared/api';
import { IpLocation } from 'shared/components/IpLocation';

interface Props {
  data?: AdminUserWithDevicesResponseDto;
}

export const UserShowContent: FC<Props> = ({ data }) => {
  return (
    <TabbedShowLayout>
      <Tab label="Основная информация">
        <WrapperField label={LABELS.phone}>
          <Link href={`tel:${data?.phone}`}>{data?.phone}</Link>
        </WrapperField>

        <TextField source="username" label={LABELS.username} />
        <DateField source="dateOfBirth" label={LABELS.dateOfBirth} />
        <TextField source="role" label={LABELS.userRole} />
        <DateField source="createdAt" label={LABELS.registratedAt} />
        {data?.deletedAt && <DateField source="deletedAt" label={LABELS.deletedAt} />}
      </Tab>
      <Tab label="Устройства">
        {data &&
          data.devices?.map(device => (
            <div
              key={device.id}
              style={{ marginBottom: '20px', width: '600px', backgroundColor: '#eee', padding: '10px' }}
            >
              <div style={{ marginBottom: '10px' }}>{device.name}</div>
              <div style={{ paddingLeft: '10px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>id:</div>
                  <div>
                    <a href={`${APP_BASE_URL}/devices/${device.id}/show`}>{device.id}</a>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>mac:</div>
                  <div>{device.mac}</div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>model:</div>
                  <div>{device.model}</div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>firmware:</div>
                  <div>{device.firmware}</div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>hardware:</div>
                  <div>{device.hardware}</div>
                </div>
              </div>
            </div>
          ))}
      </Tab>
      <Tab label="Подключения">
        {data &&
          data.connections?.map(connection => (
            <div
              key={connection.id}
              style={{ marginBottom: '20px', width: '600px', backgroundColor: '#eee', padding: '10px' }}
            >
              <div style={{ paddingLeft: '10px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>IP:</div>
                  <div>
                    {connection.ip}, {connection.region}, {connection.city}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>Browser:</div>
                  <div>{connection.browser}</div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>OS:</div>
                  <div>{connection.os}</div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>Date:</div>
                  <div>{connection.date}</div>
                </div>
              </div>
            </div>
          ))}
      </Tab>
    </TabbedShowLayout>
  );
};
