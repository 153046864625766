import { CreateButton, ExportButton, SortButton, TopToolbar } from 'react-admin';
import { SORTING_VALUES } from 'shared/constants/sorting-values';

export const HardwareListActions = ({ isCreateDisabled }: { isCreateDisabled: boolean }) => (
  <TopToolbar>
    <CreateButton disabled={isCreateDisabled} />
    <SortButton fields={[SORTING_VALUES.createdAt, SORTING_VALUES.deletedAt, SORTING_VALUES.version]} />
    <ExportButton />
  </TopToolbar>
);
