import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const DefaultDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the default page (e.g., '/admins') when the app loads
    navigate('/');
  }, [navigate]);

  return null; // Optionally, return a dashboard page or leave it empty
};