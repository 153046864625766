import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { LABELS } from 'shared/constants/labels';

export const ModelsShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" label={LABELS.id} sortable={false} />
        <TextField source="name" label={LABELS.name} />
        <DateField source="createdAt" label={LABELS.addedAt} />
        <DateField source="deletedAt" label={LABELS.deletedAt} />
      </SimpleShowLayout>
    </Show>
  );
};
