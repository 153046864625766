import { BooleanInput, Edit, SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { isRequired } from 'shared/validators';
import { FeatureToggleCustomEditToolbar } from './ui/custom-toolbar';
import { useParams } from 'react-router-dom';
import { httpClient } from 'shared/services/http-client';
import { API_BASE_URL_ADMIN } from 'shared/config';

export const FeatureToggleEdit = () => {
  const { id } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();

  if (!id) return null;

  const handleSubmit = async (data: any) => {
    try {
      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.updateFeatureToggle(id)}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
      });

      notify(`Фича ${data.name} ${data.enabled ? 'активирована' : 'деактивирована'}`, { type: 'success' });
      redirect('/feature-toggle');
    } catch (error) {
      notify('Что-то пошло не так...', { type: 'error' });
    }
  };

  return (
    <Edit>
      <SimpleForm toolbar={<FeatureToggleCustomEditToolbar />} onSubmit={handleSubmit}>
        <TextInput source="name" name="name" label={LABELS.featureToggleName} validate={[isRequired]} />
        <BooleanInput source="enabled" name="enabled" label={LABELS.isFeatureToggleEnabled} validate={[isRequired]} />
      </SimpleForm>
    </Edit>
  );
};
