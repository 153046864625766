import { FC, useMemo } from 'react';
import {
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { AdminFirmwareResponseDto, AdminHardwareResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';

import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { httpClient } from 'shared/services/http-client';
import { deleteFile } from 'shared/utils/files/delete-files';
import { moveFile } from 'shared/utils/files/move-files';
import { uploadFile } from 'shared/utils/files/upload-files';
import { FirmwareEditToolbar } from './ui/custom-toolbar';

export const FirmwareEdit = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { data: hardwares } = useHttpClient<AdminHardwareResponseDto>(API_URL.getHardwares);

  const hardwareVersionOptions = useMemo(
    () =>
      hardwares?.data.map((hardware: any) => ({
        id: hardware.id,
        version: hardware.version,
      })),
    [hardwares],
  );

  const handleSubmit = async (data: any) => {
    try {
      let fileKey = data.downloadFileKey;

      if (data.newFileKey) {
        await deleteFile(fileKey);
        const uploadedFile = await uploadFile(data.newFileKey.rawFile, data.hardware.id, data.version);
        fileKey = uploadedFile.fileKey;
      } else {
        const movedFile = await moveFile(data.downloadFileKey, data.hardware.id, data.version);
        fileKey = movedFile.fileKey;
      }

      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.updateFirmware(data.id)}`, {
        method: 'PATCH',
        body: JSON.stringify({
          hardwareId: data.hardware.id,
          version: data.version,
          downloadFileKey: fileKey,
          isDeleted: Boolean(data.deletedAt),
        }),
      });

      notify('Прошивка  обновлена', { type: 'success' });
      redirect('/firmwares');
    } catch (err: any) {
      notify(err.message, { type: 'error' });
    }
  };

  if (!hardwareVersionOptions || !props) return null;

  return (
    <Edit mutationOptions={{}}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<FirmwareEditToolbar />}>
        <TextInput source="version" />
        <SelectInput
          source="hardware.id"
          label={LABELS.hardwareId}
          inputProps={{ maxLength: 127 }}
          helperText="Уникальное, не более 127 знаков"
          choices={hardwareVersionOptions}
          optionText="version"
          fullWidth
        />
        <FileInput
          label={LABELS.downloadFileKey}
          source="newFileKey"
          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <BooleanInput source="deletedAt" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
      </SimpleForm>
    </Edit>
  );
};
