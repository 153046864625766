import { CreateButton, ExportButton, SortButton, TopToolbar, usePermissions } from 'react-admin';
import { SORTING_VALUES } from 'shared/constants/sorting-values';
import { Permissions, Roles } from '../../../../../types';
import { hasPermission } from '../../../../validators';

export const UserListActions = () => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  return (
    <TopToolbar>
      <CreateButton disabled={!hasPermission(role, Permissions.CreateUsers)}/>
      <SortButton fields={[SORTING_VALUES.createdAt, SORTING_VALUES.deletedAt]} />
      <ExportButton />
    </TopToolbar>
  );
}
