import { IMaskInput } from 'react-imask';
import { useController } from 'react-hook-form';
import { INPUT_MASKS } from 'shared/constants/inputMasks';
import './custom-phone-input.css';
import { REGEX } from 'shared/constants/regex';
import { ERROR_TEXTS } from 'shared/constants/errorTexts';

export const PhoneInput = () => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'phone',
    defaultValue: '',
    rules: {
      required: {
        value: true,
        message: ERROR_TEXTS.required,
      },
      pattern: {
        value: REGEX.phone,
        message: ERROR_TEXTS.phone,
      },
    },
  });
  const hasError = Boolean(error);

  return (
    <label className="phone-input__label">
      Телефон
      <IMaskInput mask={INPUT_MASKS.phone} {...field} overwrite />
      {hasError && <p className="phone-input__error">{error?.message}</p>}
    </label>
  );
};
