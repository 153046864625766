import { Loading, Show, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { FirmwareShowContent } from './ui/content';

export const FirmwareShow = () => {
  const { id } = useParams();

  const redirect = useRedirect();
  const { data, isLoading } = useGetOne('firmwares', { id: id as string }, { onError: () => redirect('/firmwares') });

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const pageTitle = data?.version;

  return (
    <Show title={pageTitle}>
      <FirmwareShowContent data={data} />
    </Show>
  );
};
