import { BooleanInput, Edit, Loading, SimpleForm, TextInput, useGetOne } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { isRequired } from 'shared/validators';
import { ResourseNames } from 'types';
import { ModelsCustomEditToolbar } from './ui/custom-toolbar';
import { useParams } from 'react-router-dom';

export const ModelEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetOne(ResourseNames.models, { id });
  const isDeleted = Boolean(data?.deletedAt);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <Edit>
      <SimpleForm toolbar={<ModelsCustomEditToolbar />} defaultValues={{ isDeleted }}>
        <TextInput
          source="name"
          validate={[isRequired]}
          label={LABELS.modelName}
          inputProps={{ maxLength: 127 }}
          helperText="Уникальное, не более 127 знаков"
        />

        <BooleanInput source="isDeleted" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
      </SimpleForm>
    </Edit>
  );
};
