import { CreateButton, SortButton, TopToolbar, usePermissions } from 'react-admin';
import { SORTING_VALUES } from 'shared/constants/sorting-values';
import { Permissions, Roles } from '../../../../../types';
import { hasPermission } from '../../../../validators';

export const CartridgesListActions = ({ isCreateDisabled }: { isCreateDisabled: boolean }) => {
  return (
    <TopToolbar>
      <CreateButton disabled={isCreateDisabled} />
      <SortButton fields={[SORTING_VALUES.createdAt, SORTING_VALUES.deletedAt]} />
    </TopToolbar>
  );
};
