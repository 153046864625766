import { FC } from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { AdminCartridgeResponseDto } from 'shared/api';
import { APP_BASE_URL } from 'shared/config';

import { LABELS } from 'shared/constants/labels';

interface Props {
  data?: AdminCartridgeResponseDto;
}

export const CartridgeShow: FC<Props> = ({ data }) => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" label={LABELS.id} sortable={false} />
        <TextField source="model.name" label={LABELS.model} />
        <TextField source="factoryId" label={LABELS.factoryId} />
        <TextField source="rMin" label={LABELS.rMin} />
        <TextField source="rMax" label={LABELS.rMax} />
        <TextField source="formula" label={LABELS.formula} />
        <TextField source="flavor" label={LABELS.flavor} />
        <TextField source="comment" label={LABELS.comment} />
        <DateField source="createdAt" label={LABELS.addedAt} />
        <DateField source="deletedAt" label={LABELS.deletedAt} />
      </SimpleShowLayout>
    </Show>
  );
};
