import { CreateButton, ExportButton, SortButton, TopToolbar } from 'react-admin';
import { SORTING_VALUES } from 'shared/constants/sorting-values';

export const FirmwareListActions = ({ isCreateButtonDisabled }: { isCreateButtonDisabled: boolean }) => (
  <TopToolbar>
    <SortButton fields={[SORTING_VALUES.createdAt, SORTING_VALUES.deletedAt, SORTING_VALUES.version]} />
    <CreateButton disabled={isCreateButtonDisabled} />
    <ExportButton />
  </TopToolbar>
);
