import { useEffect, useMemo, useState } from 'react';
import { httpClient } from 'shared/services/http-client';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { Query } from 'types';

interface Options {
  skip?: boolean;
  customUrl?: boolean;
}

export const useHttpClient = <Response>(url: string, options?: Options) => {
  const [{ isLoading, error, data }, setQuery] = useState<Query<Response>>({});

  useEffect(() => {
    if (options?.skip) return;

    setQuery({ isLoading: true });
    httpClient(`${!options?.customUrl ? API_BASE_URL_ADMIN : ''}${url}`)
      .then(res => {
        setQuery({ data: res.json });
      })
      .catch(error => {
        setQuery({ error });
      })
      .finally(() => {
        setQuery(prevValue => ({ ...prevValue, isLoading: false }));
      });
  }, [url, options?.skip]);

  return useMemo(() => ({ isLoading, error, data }), [isLoading, error, data]);
};
